<template class="no-scroll">
  <meta property="og:image" content="https://drive.google.com/uc?export=view&id=1xJQZc9QwMsDWNS6yNu1kEMyqXiKtORX9" />
  <meta name="description" content="Professional wedding and portrait photographer traveling the uk in my little camper shooting weddings">
  <meta name="google-site-verification" content="H7n1yxjk1UMuusSC0VE8oYdYA8G8wM0RXNvtuPPfutk" />
  <link href="styles/ihover.css" rel="stylesheet">
  <div class="no-scroll">
    <div class="skw-pages" style="font-family: 'Gruppo'">
      <div class="skw-page skw-page-1 active">
        <div class="skw-page__half skw-page__half--left">
          <router-link to="/Feature-One" style="text-decoration: none;color: white">
          <div class="skw-page__skewed" src="@/assets/1.png">
            <div class="skw-page__content" ></div>
          </div>
          </router-link>
        </div>
        <div class="skw-page__half skw-page__half--right">
          <div class="skw-page__skewed">
            <div class="skw-page__content" style="background: rgb(196,185,175);">
              <h1 style="font-size: 48px" class="skw-page__heading"> <router-link to="/Feature-One" style="text-decoration: none;color: white">Feature Wedding 1</router-link> </h1>
              <p style="font-size: 40px"><router-link to="/Feature-One" style="text-decoration: none;color: white">Martin & Micheal <br> Stirk House </router-link></p>
            </div>
          </div>
        </div>
      </div>
      <div class="skw-page skw-page-2">
        <div class="skw-page__half skw-page__half--left">
          <div class="skw-page__skewed">
            <div class="skw-page__content" style="background: rgb(196,185,175);">
              <h2 style="font-size: 48px" class="skw-page__heading"><router-link to="/Feature-Two" style="text-decoration: none;color: white">Feature Wedding 2</router-link></h2>
              <p style="font-size: 40px" class="skw-page__description">Ashton & Bethany <br> Beeston Manor </p>
            </div>
          </div>
        </div>
        <div class="skw-page__half skw-page__half--right">
          <div class="skw-page__skewed">
            <div class="skw-page__content"></div>
          </div>
        </div>
      </div>
      <div class="skw-page skw-page-3">
        <div class="skw-page__half skw-page__half--left">
          <div class="skw-page__skewed">
            <div class="skw-page__content"></div>
          </div>
        </div>
        <div class="skw-page__half skw-page__half--right">
          <div class="skw-page__skewed">
            <div class="skw-page__content" style="background: rgb(196,185,175);">
              <h2 style="font-size: 48px" class="skw-page__heading"><router-link to="/Feature-Three" style="text-decoration: none;color: white">Feature Wedding 3</router-link></h2>
              <p style="font-size: 40px" class="skw-page__description">Robert & Chelsea <br> Shrigley Hall Hotel & Spa </p>
            </div>
          </div>
        </div>
      </div>
      <div class="skw-page skw-page-4">
        <div class="skw-page__half skw-page__half--left">
          <div class="skw-page__skewed">
            <div class="skw-page__content" style="background:rgb(196,185,175);">
              <h2 style="font-size: 48px" class="skw-page__heading"><router-link to="/Feature-Four" style="text-decoration: none;color: white"> Feature Wedding 4</router-link></h2>
              <p style="font-size: 40px" class="skw-page__description">Dwain & Nicole <br> Cragwood Country House Hotel</p>
            </div>
          </div>
        </div>
        <div class="skw-page__half skw-page__half--right">
          <div class="skw-page__skewed">
            <div class="skw-page__content"></div>
          </div>
        </div>
      </div>
      <div style="display:none;" class="skw-page skw-page-5">
        <div class="skw-page__half skw-page__half--left">
          <div class="skw-page__skewed">
            <div class="skw-page__content"></div>
          </div>
        </div>
        <div class="skw-page__half skw-page__half--right">
          <div class="skw-page__skewed">
            <div class="skw-page__content" style="background: rgb(196,185,175);">
              <h2 class="skw-page__heading">Epic finale</h2>
              <p class="skw-page__description">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="FeatSml">
    <article style="font-family: 'Gruppo'">
      <div class="left">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
       <h1><router-link to="/Feature-One" style="position: relative;left: 10px; z-index: 10;text-decoration: none;color: white">Feature Wedding 1</router-link></h1>
        <br>
        <br>
        <br>
        <p style="text-decoration: none;color: white; width: 305px; position: relative; z-index: 10; padding-right: 105px;">Martin & Micheal <br> Stirk House </p>
      </div>
      <div class="right">
        <svg viewBox="0 0 40 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0l40 200H0" fill-rule="evenodd"/>
        </svg>
        <img class="image" src="@/assets/Photo.jpg" alt="" />
      </div>
    </article>
    <article style="font-family: 'Gruppo'">
      <div class="left">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h1><router-link to="/Feature-Two" style="position: relative;left: 10px; z-index: 10;text-decoration: none;color: white">Feature Wedding 2</router-link></h1>
        <br>
        <br>
        <br>
        <p style="text-decoration: none;color: white; width: 305px; position: relative; z-index: 10; padding-right: 105px;">Ashton & Bethany <br> Beeston Manor </p>
      </div>
      <div class="right">
        <svg viewBox="0 0 40 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0l40 200H0" fill-rule="evenodd"/>
        </svg>
        <img class="image" src="@/assets/untitled-33.jpg" alt="" />
      </div>
    </article>
    <article style="font-family: 'Gruppo'">
      <div class="left">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h1><router-link to="/Feature-Three" style="position: relative;left: 10px; z-index: 10;text-decoration: none;color: white">Feature Wedding 3</router-link></h1>
        <br>
        <br>
        <br>
        <p style="text-decoration: none;color: white; width: 300px; position: relative; z-index: 10; padding-right: 90px;">Robert & Chelsea <br> Shrigley Hall Hotel & Spa</p>
      </div>
      <div class="right">
        <svg viewBox="0 0 40 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0l40 200H0" fill-rule="evenodd"/>
        </svg>
        <img class="image" src="@/assets/Photos-27.jpg" alt="" />
      </div>
    </article>
    <article style="font-family: 'Gruppo'">
      <div class="left">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h1><router-link to="/Feature-Four" style="position: relative;left: 10px; z-index: 10;text-decoration: none;color: white">Feature Wedding 4</router-link></h1>
        <br>
        <br>
        <br>
        <p style="text-decoration: none;color: white; width: 305px; position: relative; z-index: 10; padding-right: 105px;">Dwain & Nicole <br> Cragwood Country House Hotel </p>
      </div>
      <div class="right">
        <svg viewBox="0 0 40 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0l40 200H0" fill-rule="evenodd"/>
        </svg>
        <img class="image" src="@/assets/Photos-41.jpg" alt="" />
      </div>
    </article>
    <article style="display: none; font-family: 'Gruppo'">
      <div class="left">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h1><router-link to="/Feature-One" style="position: relative;left: 10px; z-index: 10;text-decoration: none;color: white">Feature Wedding 5</router-link></h1>
        <br>
        <br>
        <br>
        <p style="text-decoration: none;color: white; width: 305px; position: relative; z-index: 10; padding-right: 105px;"> <br>  </p>
      </div>
      <div class="right">
        <svg viewBox="0 0 40 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0l40 200H0" fill-rule="evenodd"/>
        </svg>
        <img class="image" src="@/assets/100.jpg" alt="" />
      </div>
    </article>
  </div>
</template>

<script>

</script>

<style scoped>
article {
  max-width: 400px;
  margin: 5% auto 0;
  display: flex;
}

.left {
  width: calc(50% - 30px);
  padding: 20px 5px 20px 20px;
  background: rgb(196, 185, 175);
  height: 500px;
}

.right {
  width: calc(50% + 30px);
  background-color: #00a8a4;
  position: relative;
  height: 500px;
}
.right svg {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.right path {
  fill: rgb(196, 185, 175);
}

.wrapper {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

h1 {
  font-size: 32px;
}

p {
  font-size: 26px;
  margin: 1rem 0;
}
:root {
  overflow: hidden;
}
.no-scroll{
  overflow: hidden; /* Hide scrollbars */

}
.FeatSml{
  display: none;
}
@media (max-width: 700px) {
  .FeatSml {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .no-scroll {
    display: none;
  }
}
.skw-pages {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.skw-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.skw-page__half {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100vh;
  transition: transform 1s;
}
.skw-page__half--left {
  left: 0;
  transform: translate3d(-32.4vh, 100%, 0);
}
.skw-page__half--right {
  left: 50%;
  transform: translate3d(32.4vh, -100%, 0);
}
.skw-page.active .skw-page__half {
  transform: translate3d(0, 0, 0);
}
.skw-page__skewed {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 140%;
  height: 100%;
  transform: skewX(-18deg);
  background: #c3a6a0;
}
.skw-page__half--left .skw-page__skewed {
  left: -40%;
}
.skw-page__half--right .skw-page__skewed {
  right: -40%;
}
.skw-page__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 30%;
  color: #fff;
  transform: skewX(18deg);
  transition: transform 1s, opacity 1s;
  background-size: cover;
}
.skw-page__half--left .skw-page__content {
  padding-left: 30%;
  padding-right: 30%;
  transform-origin: 100% 0;
}
.skw-page__half--right .skw-page__content {
  padding-left: 30%;
  padding-right: 30%;
  transform-origin: 0 100%;
}
.skw-page.inactive .skw-page__content {
  opacity: 0.5;
  transform: skewX(18deg) scale(0.95);
}
.skw-page__heading {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 25px;
  text-align: center;
}
.skw-page__description {
  font-size: 18px;
  text-align: center;
}
.skw-page__link {
  color: #FFA0A0;
}
.skw-page-1 .skw-page__half--left .skw-page__content {
  background-image: url("@/assets/Photo.jpg");

}
.skw-page-1 .skw-page__half--right .skw-page__content {
  background: #292929;
}
.skw-page-2 .skw-page__half--left .skw-page__content {
  background: #292929;
}
.skw-page-2 .skw-page__half--right .skw-page__content {
  background-image: url("@/assets/untitled-33.jpg");
}
.skw-page-3 .skw-page__half--left .skw-page__content {
  background-image: url("@/assets/Photos-27.jpg");
}
.skw-page-3 .skw-page__half--right .skw-page__content {
  background: #292929;
}
.skw-page-4 .skw-page__half--left .skw-page__content {
  background: #292929;
}
.skw-page-4 .skw-page__half--right .skw-page__content {
  background-image: url("@/assets/Photos-41.jpg");
}
.skw-page-5 .skw-page__half--left .skw-page__content {
  background-image: url("@/assets/100.jpg");
}
.skw-page-5 .skw-page__half--right .skw-page__content {
  background: #292929;
}

@media (max-width: 1024px) {
  .carousel-inner{
    height: 50vh
  }
  .feature{
    height: 50vh;
  }

}
@media (min-width: 1024px) {
  .carousel-inner{
    height: 100vh
  }

}
</style>
